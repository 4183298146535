<template>
  <div v-html="legacySystemHTML"></div>
</template>

<script>
import toSvg from "./../toSvg";
export default {
  data() {
    return {
      legacySystemHTML: `
      <?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="77.222649mm"
   height="80.190201mm"
   viewBox="0 0 77.222649 99.190201"
   version="1.1"
   id="svg5"
   xml:space="preserve"
   inkscape:version="1.2 (dc2aedaf03, 2022-05-15)"
   sodipodi:docname="M_E_6CFS_3Dec_.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview7"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     showgrid="true"
     inkscape:zoom="1.4912879"
     inkscape:cx="-44.257048"
     inkscape:cy="260.1778"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="layer1"><inkscape:grid
       type="xygrid"
       id="grid107586" /></sodipodi:namedview><defs
     id="defs2"><filter
       inkscape:collect="always"
       style="color-interpolation-filters:sRGB"
       id="filter4555-7"
       x="-1.0552142e-05"
       width="1.0000211"
       y="-0.00010639225"
       height="1.0002128"><feGaussianBlur
         inkscape:collect="always"
         stdDeviation="0.0011536701"
         id="feGaussianBlur4557-9" /></filter><style
       type="text/css"
       id="style2852">
   <![CDATA[
    .str0 {stroke:#1F1A17;stroke-width:0.374252}
    .str2 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round}
    .str1 {stroke:#1F1A17;stroke-width:0.374252;stroke-dasharray:1.871260 1.122756}
    .str4 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.122756}
    .str3 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.871260}
    .fil0 {fill:none}
    .fil1 {fill:#1F1A17;fill-rule:nonzero}
   ]]>
  </style><style
       id="style23281">.cls-1{fill:none;}</style></defs><g
     inkscape:label="Capa 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-85.227387,-90.921029)"><g
       id="Capa_10_copia"
       transform="matrix(0.26458333,0,0,0.26458333,-8.7084723,51.930251)">
	</g><path
       id="rect3318-5"
       style="fill:#ebeeef;fill-opacity:1;stroke-width:0.44144"
       d="m 85.814304,159.47418 h 76.634916 v 30.63705 H 85.814304 Z" /><path
       id="rect3320-1"
       style="fill:#d29403;fill-opacity:1;stroke-width:1.00014;filter:url(#filter4555-7)"
       d="M 3.9756525,175.42555 H 266.36866 v 26.02453 H 3.9756525 Z"
       transform="matrix(0.29206216,0,0,0.25016633,84.653018,115.5886)" /><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="112.64575"
       y="165.94705"
       id="text294-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-7"
         x="112.64575"
         y="165.94705"
         style="font-size:3.46461px;fill:#ffffff;stroke-width:0.28872">Información</tspan></text><text
       xml:space="preserve"
       style="font-size:4.79112px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.299447"
       x="88.905998"
       y="177.71822"
       id="text294-2-5"
       transform="scale(1.0491626,0.95314111)"><tspan
         sodipodi:role="line"
         id="tspan292-1-99"
         x="88.905998"
         y="177.71822"
         style="font-size:3.59333px;fill:#232626;fill-opacity:1;stroke-width:0.299447"><tspan
           style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.299447"
           id="tspan478-4">ID Disp. :</tspan></tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="98.024284"
       y="175.80244"
       id="text294-2-2-4"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-1-9"
         x="84.024284"
         y="175.80244"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">Alias :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="90.514687"
       y="180.34782"
       id="text294-2-0-10"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-9-2"
         x="90.514687"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">Ubicación :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.58558px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.286601"
       x="84.874893"
       y="183.35675"
       id="text294-2-8-2"
       transform="scale(1.0041531,0.99586407)"><tspan
         sodipodi:role="line"
         x="84.874893"
         y="183.35675"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.286601"
         id="tspan380-3">  Ult. medición :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.07968px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78088"
       y="171.38197"
       id="text294-2-02-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-device"
         x="111.78088"
         y="171.38197"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">00000000</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="92.910698"
       y="189.25705"
       id="text294-2-8-9-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="92.910698"
         y="189.25705"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="tspan380-4-4">  Pulsos  :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="112.16678"
       y="175.80244"
       id="text294-2-2-6-2"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-Alias"
         x="99.16678"
         y="175.80244"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">-</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78037"
       y="180.34782"
       id="text294-2-0-1-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-ubicacion"
         x="111.78037"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">0,0</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.7187"
       y="184.71269"
       id="text294-2-8-0-0"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.7187"
         y="184.71269"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-UltimaAct">00/00/00   00:00:00</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="110.80153"
       y="189.07758"
       id="text294-2-8-9-7-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="110.80153"
         y="189.07758"
         style="font-size:4.07968px;fill:#1a1a1a;stroke-width:0.28872"
         id="tspan380-4-8-5">  </tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.91395"
       y="189.43652"
       id="text294-2-8-9-2-9"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.91395"
         y="189.43652"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-Pulsos">0</tspan></text><g
       id="Capa_x0020_1"
       inkscape:label="Capa 1"
       inkscape:groupmode="layer"
       transform="matrix(0.23517337,0,0,0.25639214,157.04571,70.316583)"
       style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision">
  <metadata
   id="CorelCorpID_0Corel-Layer" />
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
 <g
   data-name="Layer 2"
   id="Layer_2"
   transform="matrix(1.1250565,0,0,1.0192143,-35.784344,98.528906)"
   style="display:inline;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><g
     id="S-Fraude"
     style="display:none;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><path
       d="M 22.7,28 H 9.3 a 6.25,6.25 0 0 1 -5.47,-3.15 6.15,6.15 0 0 1 0,-6.22 L 10.56,7.12 a 6.3,6.3 0 0 1 10.88,0 l 6.71,11.51 v 0 a 6.15,6.15 0 0 1 0,6.22 A 6.25,6.25 0 0 1 22.7,28 Z M 16,6 A 4.24,4.24 0 0 0 12.29,8.12 L 5.58,19.64 a 4.15,4.15 0 0 0 0,4.21 A 4.23,4.23 0 0 0 9.3,26 h 13.4 a 4.23,4.23 0 0 0 3.73,-2.15 4.15,4.15 0 0 0 0,-4.21 L 19.71,8.12 A 4.24,4.24 0 0 0 16,6 Z"
       id="path23287"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       class="cls-1"
       d="m 16,12 a 0.54,0.54 0 0 0 -0.44,0.22 0.52,0.52 0 0 0 -0.1,0.48 L 16,14.88 16.54,12.7 A 0.52,0.52 0 0 0 16.44,12.22 0.54,0.54 0 0 0 16,12 Z"
       id="path23289"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       d="m 18,11 a 2.56,2.56 0 0 0 -4,0 2.5,2.5 0 0 0 -0.46,2.19 L 15,19.24 a 1,1 0 0 0 1.94,0 l 1.51,-6.06 A 2.5,2.5 0 0 0 18,11 Z M 16.54,12.7 16,14.88 15.46,12.7 a 0.52,0.52 0 0 1 0.1,-0.48 0.55,0.55 0 0 1 0.88,0 0.52,0.52 0 0 1 0.1,0.48 z"
       id="path23291"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><circle
       cx="16"
       cy="22.5"
       r="1.5"
       id="circle23293"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /></g></g><g
   id="frame"
   transform="matrix(0.91053163,0,0,0.83850055,-90.100031,336.92569)"><rect
     class="cls-1"
     height="32"
     width="32"
     id="rect23296"
     x="0"
     y="0" /></g><polygon
   style="display:inline;fill:#e6e6e6;fill-opacity:1"
   points="32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 "
   id="checkConnect-5"
   transform="matrix(1.0071785,0,0,1.0856859,-26.521454,426.97583)" /></g><g
       id="Capa_9_copia"
       transform="matrix(0.09683006,0,0,0.12226541,93.217509,90.032062)"
       style="display:inline">
	<text
   transform="translate(126.5329,181.4912)"
   class="st0 st1 st2"
   id="text11903"
   style="font-size:15.1008px;font-family:MyriadPro-Regular;fill:#333435">MEDIDOR DE ENERGÍA ACTIVA</text>
	<text
   transform="translate(112.4213,202.0626)"
   id="text11909"><tspan
     x="0"
     y="0"
     class="st0 st1 st3"
     id="tspan11905">SGM81L</tspan><tspan
     x="60.860001"
     y="0"
     class="st0 st1 st2"
     id="tspan11907">MONOFÁSICO 2 HILOS</tspan></text>
	<g
   id="g11913">
		<text
   transform="translate(120.1758,310.7636)"
   class="st0 st1 st2"
   id="text11911"
   style="font-size:15.1008px;font-family:MyriadPro-Regular;fill:#333435">INTEGRADOR SIEMPRE POSITIVO</text>
	</g>
	<g
   id="g11923">
		<g
   id="g11917">
			<text
   transform="translate(120.9585,236.9832)"
   class="st0 st1 st2"
   id="text11915"
   style="font-size:15.1008px;font-family:MyriadPro-Regular;fill:#333435">REVERSA</text>
		</g>
		<g
   id="g11921">
			<text
   transform="translate(259.5475,236.9836)"
   class="st0 st1 st2"
   id="text11919"
   style="font-size:15.1008px;font-family:MyriadPro-Regular;fill:#333435">1600 imp/kWh</text>
		</g>
	</g>
	<g
   id="g11929">
		<g
   id="g11927">
			<text
   transform="translate(98.5236,257.1922)"
   class="st0 st1 st2"
   id="text11925"
   style="font-size:15.1008px;font-family:MyriadPro-Regular;fill:#333435">200VAC 5 (60) A 60HZ CLASE 1.0</text>
		</g>
	</g>
	<text
   transform="translate(102.5925,283.8624)"
   id="text11935"><tspan
     x="0"
     y="0"
     class="st0 st1 st2"
     id="tspan11931">SERIE:</tspan><tspan
     x="66.760002"
     y="0"
     class="st0 st1 st4"
     id="tspan11933">20190088943</tspan></text>
	<text
   transform="translate(207.1273,349.6649)"
   class="st0 st1 st4"
   id="text11937"
   style="font-size:21px;font-family:MyriadPro-Regular;fill:#333435">ISO9001</text>
	<g
   id="g11941">
		<text
   transform="translate(246.9917,382.4287)"
   class="st0 st1 st5"
   id="text11939"
   style="font-size:13.4951px;font-family:MyriadPro-Regular;fill:#333435">HECHO EN CHINA</text>
	</g>
	<g
   id="g11945">
		<text
   transform="translate(230.6548,82.4434)"
   class="st0 st1 st6"
   id="text11943"
   style="font-size:12.1191px;font-family:MyriadPro-Regular;fill:#333435">IEC62062-11 IEC62053-21</text>
	</g>
	<g
   id="g11949">
		<text
   transform="translate(327.3071,128.5928)"
   class="st0 st1 st7"
   id="text11947"
   style="font-size:16.5828px;font-family:MyriadPro-Regular;fill:#333435">kWh</text>
	</g>
	<text
   transform="matrix(2.8938,0,0,1,111.8188,339.6885)"
   class="st0 st1 st8"
   id="text11951"
   style="font-size:9.9589px;font-family:MyriadPro-Regular;fill:#333435">PA</text>
	<text
   transform="translate(132.2637,135.9814)"
   id="text11959"><tspan
     x="0"
     y="0"
     class="st9 st10"
     id="tspan11953">000000.</tspan><tspan
     x="121.17"
     y="0"
     class="st9 st10 st11"
     id="tspan11955">0</tspan><tspan
     x="144.88"
     y="0"
     class="st9 st12"
     id="tspan11957">kWh</tspan></text>
</g><path
       class="st13"
       d="m 139.20972,96.293292 c -0.21144,-1.26544 -1.2065,-2.23868 -2.45001,-2.40619 -4.21674,-0.56731 -13.88182,-1.77652 -19.74586,-1.77652 -5.86403,0 -15.53029,1.20921 -19.745835,1.77652 -1.242317,0.16751 -2.237379,1.14075 -2.448809,2.40619 -1.057179,6.316248 -1.636534,13.194908 -1.636534,20.392668 0,1.81564 0.06211,3.6105 0.181571,5.38091 0.08481,1.2532 0.137373,2.5101 0.169626,3.76576 0.121844,4.74024 0.351196,9.27873 0.711947,13.53478 l 0.19113,2.2607 h 2.715209 39.723425 2.71519 l 0.19114,-2.2607 c 0.38941,-4.60451 0.62593,-9.54037 0.73942,-14.70241 0.0143,-0.63822 0.0442,-1.27523 0.092,-1.91101 0.15171,-1.99292 0.23055,-4.01764 0.23055,-6.06803 9.5e-4,-7.19654 -0.57817,-14.07642 -1.63414,-20.392668 z"
       id="path11962"
       style="display:inline;fill:#bbc6c8;fill-opacity:0.988235;stroke-width:0.120852" /><text
       xml:space="preserve"
       style="font-size:1.36574px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke-width:0.256075"
       x="125.39147"
       y="129.3053"
       id="text14033-2"
       transform="scale(0.97858398,1.0218847)"><tspan
         sodipodi:role="line"
         id="tspan14031-4"
         x="125.39147"
         y="129.3053"
         style="font-size:1.53645px;fill:#232626;fill-opacity:1;stroke-width:0.256075">M.C V2.0</tspan></text><path
       class="st14"
       d="M 138.03786,156.05542 H 95.989848 c -0.857672,0 -1.552902,-0.71159 -1.552902,-1.58945 v -12.83786 h 45.153824 v 12.83786 c 0,0.87786 -0.69522,1.58945 -1.55291,1.58945 z"
       id="path11964"
       style="display:inline;fill:#5b7075;fill-opacity:0.988235;stroke-width:0.120852" /><rect
       x="95.838135"
       y="142.60616"
       class="st15"
       width="42.351494"
       height="12.471072"
       id="rect11966"
       style="display:inline;fill:#354144;fill-opacity:1;stroke-width:0.120852" /><path
       class="st16"
       d="m 134.97148,96.878952 c -1.30564,-1.38404 -8.84801,-2.44532 -17.95763,-2.44532 -9.10962,0 -16.65197,1.06128 -17.957599,2.44532 -0.1314,0.13937 -0.19948,0.28243 -0.19948,0.42793 v 39.308338 c 0,1.01236 0.802743,1.83399 1.791809,1.83399 h 32.73057 c 0.98909,0 1.79182,-0.82163 1.79182,-1.83399 V 97.306882 c 0,-0.1455 -0.0681,-0.28856 -0.19949,-0.42793 z m -0.45751,39.551648 c 0,0.67491 -0.53515,1.22266 -1.19454,1.22266 h -32.61111 c -0.65939,0 -1.194556,-0.54775 -1.194556,-1.22266 V 97.705462 c 0,-0.0404 0.008,-0.0794 0.0203,-0.11861 0.43841,-1.3119 8.096606,-2.35727 17.479776,-2.35727 9.38318,0 17.04142,1.04537 17.47981,2.35727 0.0132,0.0392 0.0203,0.0782 0.0203,0.11861 z"
       id="path11968"
       style="display:inline;fill:#dce2e3;fill-opacity:0.988235;stroke-width:0.120852" /><path
       class="st17"
       d="m 138.03786,156.05542 h -3.57405 v -6.23554 h 5.12696 v 4.64609 c 0,0.87786 -0.69522,1.58945 -1.55291,1.58945 z"
       id="path11970"
       style="display:inline;fill:#a7b5b9;fill-opacity:0.988235;stroke-width:0.120852" /><path
       class="st17"
       d="m 99.563944,156.05542 h -3.574096 c -0.857672,0 -1.552902,-0.71159 -1.552902,-1.58945 v -4.64609 h 5.126998 z"
       id="path11972"
       style="display:inline;fill:#a7b5b9;fill-opacity:0.988235;stroke-width:0.120852" /><path
       class="st17"
       d="m 99.084931,146.02965 h -4.647985 v -4.40154 h 5.364718 v 3.66796 c 0,0.4047 -0.32133,0.73358 -0.716733,0.73358 z"
       id="path11974"
       style="display:inline;fill:#a7b5b9;fill-opacity:0.988235;stroke-width:0.120852" /><path
       class="st17"
       d="m 134.93803,146.02965 h 4.64796 v -4.40154 h -5.36468 v 3.66796 c 0,0.4047 0.32013,0.73358 0.71672,0.73358 z"
       id="path11976"
       style="display:inline;fill:#a7b5b9;fill-opacity:0.988235;stroke-width:0.120852" /><ellipse
       class="st18"
       cx="96.945503"
       cy="153.12097"
       id="circle11978"
       rx="1.7918215"
       ry="1.8339812"
       style="display:inline;fill:#4e4e53;stroke-width:0.120852" /><ellipse
       class="st18"
       cx="137.0824"
       cy="153.12097"
       id="circle11980"
       rx="1.7918215"
       ry="1.8339812"
       style="display:inline;fill:#4e4e53;stroke-width:0.120852" /><ellipse
       class="st19"
       cx="97.542763"
       cy="143.95108"
       id="circle11982"
       rx="1.3140025"
       ry="1.3449194"
       style="display:inline;fill:#b57f02;fill-opacity:1;stroke-width:0.120852" /><ellipse
       class="st19"
       cx="136.48514"
       cy="143.95108"
       id="circle11984"
       rx="1.3140025"
       ry="1.3449194"
       style="display:inline;fill:#b57f02;fill-opacity:1;stroke-width:0.120852" /><g
       id="Capa_4_copia"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline">
		<circle
   class="st20"
   cx="217.5"
   cy="476.5"
   r="12.5"
   id="circle11987"
   style="fill:#c1c1bd" />
		<circle
   class="st20"
   cx="51.5"
   cy="471.5"
   r="8.5"
   id="circle11989"
   style="fill:#c1c1bd" />
		<circle
   class="st20"
   cx="135.5"
   cy="449.5"
   r="8.5"
   id="circle11991"
   style="fill:#c1c1bd" />
		<circle
   class="st20"
   cx="301.5"
   cy="449.5"
   r="8.5"
   id="circle11993"
   style="fill:#c1c1bd" />
		<circle
   class="st20"
   cx="384.5"
   cy="471.5"
   r="8.5"
   id="circle11995"
   style="fill:#c1c1bd" />
		<g
   id="g12003">
			<rect
   x="86"
   y="441"
   class="st21"
   width="24"
   height="68"
   id="rect11997"
   style="fill:#101012" />
			<circle
   class="st20"
   cx="98.5"
   cy="458.5"
   r="10.5"
   id="circle11999"
   style="fill:#c1c1bd" />
			<circle
   class="st20"
   cx="98.5"
   cy="490.5"
   r="10.5"
   id="circle12001"
   style="fill:#c1c1bd" />
		</g>
		<g
   id="g12011">
			<rect
   x="160"
   y="441"
   class="st21"
   width="24"
   height="68"
   id="rect12005"
   style="fill:#101012" />
			<circle
   class="st20"
   cx="172.5"
   cy="458.5"
   r="10.5"
   id="circle12007"
   style="fill:#c1c1bd" />
			<circle
   class="st20"
   cx="172.5"
   cy="490.5"
   r="10.5"
   id="circle12009"
   style="fill:#c1c1bd" />
		</g>
		<g
   id="g12019">
			<rect
   x="326"
   y="441"
   class="st21"
   width="24"
   height="68"
   id="rect12013"
   style="fill:#101012" />
			<circle
   class="st20"
   cx="338.5"
   cy="458.5"
   r="10.5"
   id="circle12015"
   style="fill:#c1c1bd" />
			<circle
   class="st20"
   cx="338.5"
   cy="490.5"
   r="10.5"
   id="circle12017"
   style="fill:#c1c1bd" />
		</g>
		<g
   id="g12027">
			<rect
   x="252"
   y="441"
   class="st21"
   width="24"
   height="68"
   id="rect12021"
   style="fill:#101012" />
			<circle
   class="st20"
   cx="264.5"
   cy="458.5"
   r="10.5"
   id="circle12023"
   style="fill:#c1c1bd" />
			<circle
   class="st20"
   cx="264.5"
   cy="490.5"
   r="10.5"
   id="circle12025"
   style="fill:#c1c1bd" />
		</g>
	</g><g
       id="g12039"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12033"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 51.45,465.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.37,-2.13 0.78,-3.2 z"
   id="path12031"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12037"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 57.85,471.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.14,0.36 3.2,0.78 z"
   id="path12035"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12049"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12043"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 98.5,452.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12041"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12047"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 104.9,458.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12045"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12059"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12053"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 98.5,484.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12051"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12057"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 104.9,490.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12055"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12069"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12063"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 172.5,484.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12061"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12067"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 178.9,490.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12065"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12079"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12073"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 172.5,452.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12071"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12077"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 178.9,458.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12075"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12089"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12083"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 135.5,443.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12081"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12087"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 141.9,449.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12085"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12099"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12093"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 217.5,470.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12091"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12097"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 223.9,476.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12095"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12109"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12103"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 264.5,452.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12101"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12107"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 270.9,458.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12105"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12119"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12113"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 264.5,484.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.21,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12111"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12117"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 270.9,490.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12115"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12129"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12123"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 338.5,484.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.22,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12121"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12127"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 344.9,490.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12125"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12139"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12133"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 338.5,452.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.22,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12131"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12137"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 344.9,458.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12135"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12149"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12143"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 301.5,443.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.22,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12141"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12147"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 307.9,449.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12145"
   style="fill:#e6e6e6" />
			</g>
		</g><g
       id="g12159"
       style="display:inline;opacity:0.98;fill:#e6e6e6"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)">
			<g
   id="g12153"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 384.5,465.1 c 0.42,1.07 0.62,2.13 0.78,3.2 0.15,1.07 0.21,2.13 0.22,3.2 0,1.07 -0.07,2.13 -0.21,3.2 -0.16,1.07 -0.37,2.13 -0.79,3.2 -0.42,-1.07 -0.63,-2.13 -0.79,-3.2 -0.15,-1.07 -0.22,-2.13 -0.21,-3.2 0,-1.07 0.07,-2.13 0.22,-3.2 0.16,-1.07 0.36,-2.13 0.78,-3.2 z"
   id="path12151"
   style="fill:#e6e6e6" />
			</g>
			<g
   id="g12157"
   style="fill:#e6e6e6">
				<path
   class="st22"
   d="m 390.9,471.5 c -1.07,0.42 -2.13,0.62 -3.2,0.78 -1.07,0.15 -2.13,0.21 -3.2,0.22 -1.07,0 -2.13,-0.07 -3.2,-0.21 -1.07,-0.16 -2.13,-0.37 -3.2,-0.79 1.07,-0.42 2.13,-0.63 3.2,-0.79 1.07,-0.15 2.13,-0.21 3.2,-0.21 1.07,0 2.13,0.07 3.2,0.22 1.07,0.16 2.13,0.36 3.2,0.78 z"
   id="path12155"
   style="fill:#e6e6e6" />
			</g>
		</g><rect
       x="101.76118"
       y="101.15823"
       class="st23"
       width="30.648178"
       height="7.3359246"
       id="rect12162"
       style="display:inline;fill:#8b8d8f;stroke-width:0.134871" /><rect
       x="102.69067"
       y="102.01406"
       class="st24"
       width="28.777225"
       height="5.6242094"
       id="rect12164"
       style="display:inline;fill:#e3f792;fill-opacity:1;stroke-width:0.136082" /><ellipse
       class="st21"
       cx="117.37891"
       cy="118.30194"
       id="circle12170"
       rx="1.2759564"
       ry="1.3183396"
       style="display:inline;fill:#232626;fill-opacity:1;stroke-width:0.136523" /><ellipse
       class="st25"
       cx="117.39462"
       cy="118.28627"
       id="circle12172"
       rx="0.81381923"
       ry="0.84495962"
       style="display:inline;fill:#cf2c03;fill-opacity:1;stroke-width:0.150772" /><polygon
       class="st26"
       points="254.11,225.64 254.11,239.36 240.67,232.5 "
       id="polygon12174"
       transform="matrix(0.11945477,0,0,0.12226541,90.471576,90.032062)"
       style="display:inline;fill:#b57f02;fill-opacity:1" /><rect
       x="136.24623"
       y="136.68483"
       class="st27"
       width="2.1501858"
       height="4.0873322"
       id="rect12177"
       style="display:inline;fill:#232626;fill-opacity:1;stroke-width:0.120852" /><rect
       x="95.631477"
       y="136.68483"
       class="st27"
       width="2.1501858"
       height="4.0873322"
       id="rect12179"
       style="display:inline;fill:#232626;fill-opacity:1;stroke-width:0.120852" /><g
       id="g12226"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline;fill:#232626;fill-opacity:1">
		<path
   class="st0"
   d="m 134.77,177.02 c -0.08,-1.42 -0.17,-3.13 -0.15,-4.4 h -0.04 c -0.35,1.19 -0.77,2.46 -1.28,3.87 l -1.8,4.94 h -1 l -1.65,-4.85 c -0.48,-1.44 -0.89,-2.75 -1.18,-3.96 h -0.03 c -0.03,1.27 -0.11,2.98 -0.2,4.5 l -0.27,4.37 h -1.25 l 0.71,-10.18 h 1.68 l 1.74,4.92 c 0.42,1.25 0.77,2.37 1.03,3.43 h 0.05 c 0.26,-1.03 0.62,-2.15 1.07,-3.43 l 1.81,-4.92 h 1.68 l 0.63,10.18 h -1.28 z"
   id="path12182"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 143.37,176.72 h -3.96 v 3.67 h 4.41 v 1.1 h -5.73 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 z"
   id="path12184"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 145.12,171.45 c 0.8,-0.12 1.75,-0.21 2.79,-0.21 1.89,0 3.23,0.44 4.12,1.27 0.91,0.83 1.44,2.01 1.44,3.66 0,1.66 -0.51,3.02 -1.47,3.96 -0.95,0.95 -2.52,1.47 -4.5,1.47 -0.94,0 -1.72,-0.05 -2.39,-0.12 v -10.03 z m 1.32,9 c 0.33,0.06 0.82,0.08 1.33,0.08 2.81,0 4.34,-1.57 4.34,-4.32 0.02,-2.4 -1.34,-3.93 -4.12,-3.93 -0.68,0 -1.19,0.06 -1.54,0.14 v 8.03 z"
   id="path12186"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 156.29,171.31 v 10.18 h -1.31 v -10.18 z"
   id="path12188"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 158.3,171.45 c 0.8,-0.12 1.75,-0.21 2.79,-0.21 1.89,0 3.23,0.44 4.12,1.27 0.91,0.83 1.44,2.01 1.44,3.66 0,1.66 -0.51,3.02 -1.47,3.96 -0.95,0.95 -2.52,1.47 -4.5,1.47 -0.94,0 -1.72,-0.05 -2.39,-0.12 v -10.03 z m 1.32,9 c 0.33,0.06 0.82,0.08 1.33,0.08 2.81,0 4.34,-1.57 4.34,-4.32 0.02,-2.4 -1.34,-3.93 -4.12,-3.93 -0.68,0 -1.19,0.06 -1.54,0.14 v 8.03 z"
   id="path12190"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 176.89,176.29 c 0,3.5 -2.13,5.36 -4.73,5.36 -2.69,0 -4.58,-2.08 -4.58,-5.17 0,-3.23 2.01,-5.35 4.73,-5.35 2.79,0.01 4.58,2.14 4.58,5.16 z m -7.9,0.17 c 0,2.18 1.18,4.12 3.25,4.12 2.08,0 3.26,-1.92 3.26,-4.23 0,-2.02 -1.06,-4.14 -3.25,-4.14 -2.17,0.01 -3.26,2.02 -3.26,4.25 z"
   id="path12192"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 178.39,171.45 c 0.66,-0.14 1.62,-0.21 2.52,-0.21 1.4,0 2.31,0.26 2.95,0.83 0.51,0.45 0.8,1.15 0.8,1.93 0,1.34 -0.85,2.24 -1.92,2.6 v 0.05 c 0.79,0.27 1.25,1 1.5,2.05 0.33,1.42 0.57,2.4 0.79,2.79 h -1.36 c -0.17,-0.29 -0.39,-1.16 -0.68,-2.43 -0.3,-1.4 -0.85,-1.93 -2.04,-1.98 h -1.24 v 4.41 h -1.31 v -10.04 z m 1.32,4.63 h 1.34 c 1.4,0 2.3,-0.77 2.3,-1.93 0,-1.31 -0.95,-1.89 -2.34,-1.9 -0.63,0 -1.09,0.06 -1.3,0.12 z"
   id="path12194"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 189.42,171.45 c 0.8,-0.12 1.75,-0.21 2.79,-0.21 1.89,0 3.23,0.44 4.12,1.27 0.91,0.83 1.44,2.01 1.44,3.66 0,1.66 -0.51,3.02 -1.47,3.96 -0.95,0.95 -2.52,1.47 -4.5,1.47 -0.94,0 -1.72,-0.05 -2.39,-0.12 v -10.03 z m 1.31,9 c 0.33,0.06 0.82,0.08 1.33,0.08 2.81,0 4.34,-1.57 4.34,-4.32 0.02,-2.4 -1.34,-3.93 -4.12,-3.93 -0.68,0 -1.19,0.06 -1.54,0.14 v 8.03 z"
   id="path12196"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 204.51,176.72 h -3.96 v 3.67 h 4.41 v 1.1 h -5.73 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 z"
   id="path12198"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 214.84,176.72 h -3.96 v 3.67 h 4.41 v 1.1 h -5.73 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 z"
   id="path12200"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 216.6,181.49 v -10.18 h 1.44 l 3.26,5.15 c 0.76,1.19 1.34,2.27 1.83,3.31 l 0.03,-0.02 c -0.12,-1.36 -0.15,-2.6 -0.15,-4.18 v -4.26 h 1.24 v 10.18 h -1.33 l -3.23,-5.17 c -0.71,-1.13 -1.39,-2.3 -1.9,-3.4 l -0.04,0.02 c 0.08,1.28 0.11,2.51 0.11,4.2 v 4.35 z"
   id="path12202"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 231.56,176.72 h -3.96 v 3.67 h 4.41 v 1.1 h -5.73 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 z"
   id="path12204"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 233.31,171.45 c 0.67,-0.14 1.62,-0.21 2.52,-0.21 1.4,0 2.31,0.26 2.95,0.83 0.51,0.45 0.8,1.15 0.8,1.93 0,1.34 -0.85,2.24 -1.92,2.6 v 0.05 c 0.79,0.27 1.25,1 1.5,2.05 0.33,1.42 0.57,2.4 0.79,2.79 h -1.36 c -0.17,-0.29 -0.39,-1.16 -0.68,-2.43 -0.3,-1.4 -0.85,-1.93 -2.04,-1.98 h -1.24 v 4.41 h -1.31 v -10.04 z m 1.32,4.63 h 1.34 c 1.4,0 2.3,-0.77 2.3,-1.93 0,-1.31 -0.95,-1.89 -2.34,-1.9 -0.63,0 -1.09,0.06 -1.3,0.12 z"
   id="path12206"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 248.9,181.04 c -0.59,0.21 -1.75,0.56 -3.13,0.56 -1.54,0 -2.81,-0.39 -3.81,-1.34 -0.88,-0.85 -1.42,-2.21 -1.42,-3.79 0.02,-3.04 2.1,-5.26 5.51,-5.26 1.18,0 2.1,0.26 2.54,0.47 l -0.32,1.07 c -0.54,-0.24 -1.22,-0.44 -2.25,-0.44 -2.48,0 -4.09,1.54 -4.09,4.09 0,2.58 1.56,4.11 3.93,4.11 0.86,0 1.45,-0.12 1.75,-0.27 v -3.04 h -2.07 v -1.06 h 3.35 v 4.9 z"
   id="path12208"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 253.49,169 -1.93,1.77 h -1.07 l 1.39,-1.77 z m -1.56,2.31 v 10.18 h -1.31 v -10.18 z"
   id="path12210"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 255.47,178.29 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.41 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12212"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 267.61,178.29 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.4 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12214"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 281.64,181.16 c -0.48,0.24 -1.45,0.48 -2.69,0.48 -2.87,0 -5.03,-1.81 -5.03,-5.15 0,-3.19 2.16,-5.35 5.32,-5.35 1.27,0 2.07,0.27 2.42,0.45 l -0.32,1.07 c -0.5,-0.24 -1.21,-0.42 -2.05,-0.42 -2.39,0 -3.97,1.53 -3.97,4.2 0,2.49 1.43,4.09 3.91,4.09 0.8,0 1.62,-0.17 2.15,-0.42 z"
   id="path12216"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 285.37,172.43 h -3.1 v -1.12 h 7.54 v 1.12 h -3.11 v 9.06 h -1.33 z"
   id="path12218"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 292.07,171.31 v 10.18 h -1.31 v -10.18 z"
   id="path12220"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 296.36,181.49 -3.32,-10.18 h 1.42 l 1.59,5.02 c 0.44,1.38 0.82,2.61 1.09,3.81 h 0.03 c 0.29,-1.18 0.71,-2.46 1.16,-3.79 l 1.72,-5.03 h 1.41 l -3.64,10.18 h -1.46 z"
   id="path12222"
   style="fill:#232626;fill-opacity:1" />
		<path
   class="st0"
   d="m 303.17,178.29 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.41 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12224"
   style="fill:#232626;fill-opacity:1" />
	</g><g
       id="g12326"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline;fill:#232626;fill-opacity:1">
		<g
   id="g12324"
   style="fill:#232626;fill-opacity:1">
			<path
   class="st0"
   d="m 121.1,300.58 v 10.18 h -1.31 v -10.18 z"
   id="path12274"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 123.11,310.76 v -10.18 h 1.44 l 3.26,5.15 c 0.76,1.19 1.34,2.27 1.83,3.31 l 0.03,-0.01 c -0.12,-1.36 -0.15,-2.6 -0.15,-4.18 v -4.26 h 1.24 v 10.18 h -1.33 l -3.23,-5.17 c -0.71,-1.13 -1.39,-2.3 -1.9,-3.4 l -0.04,0.01 c 0.08,1.28 0.11,2.51 0.11,4.2 v 4.35 z"
   id="path12276"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 134.81,301.7 h -3.1 v -1.12 h 7.54 v 1.12 h -3.11 v 9.06 h -1.33 z"
   id="path12278"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 145.47,305.99 h -3.96 v 3.67 h 4.41 v 1.1 h -5.73 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 z"
   id="path12280"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 154.97,310.31 c -0.59,0.21 -1.75,0.56 -3.13,0.56 -1.54,0 -2.81,-0.39 -3.81,-1.34 -0.88,-0.85 -1.42,-2.21 -1.42,-3.79 0.02,-3.04 2.1,-5.26 5.51,-5.26 1.18,0 2.1,0.26 2.54,0.47 l -0.32,1.07 c -0.54,-0.24 -1.22,-0.44 -2.25,-0.44 -2.48,0 -4.09,1.54 -4.09,4.09 0,2.58 1.56,4.11 3.93,4.11 0.86,0 1.45,-0.12 1.75,-0.27 v -3.04 h -2.07 v -1.06 h 3.35 v 4.9 z"
   id="path12282"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 156.76,300.72 c 0.67,-0.14 1.62,-0.21 2.52,-0.21 1.4,0 2.31,0.26 2.95,0.83 0.51,0.45 0.8,1.15 0.8,1.93 0,1.34 -0.85,2.24 -1.92,2.6 v 0.04 c 0.79,0.27 1.25,1 1.5,2.05 0.33,1.42 0.57,2.4 0.79,2.79 h -1.36 c -0.17,-0.29 -0.39,-1.16 -0.68,-2.43 -0.3,-1.4 -0.85,-1.93 -2.04,-1.98 h -1.24 v 4.41 h -1.31 v -10.03 z m 1.31,4.64 h 1.34 c 1.4,0 2.3,-0.77 2.3,-1.93 0,-1.31 -0.95,-1.89 -2.34,-1.9 -0.63,0 -1.09,0.06 -1.3,0.12 z"
   id="path12284"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 166.54,307.56 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.4 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12286"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 173.77,300.72 c 0.8,-0.12 1.75,-0.21 2.79,-0.21 1.89,0 3.23,0.44 4.12,1.27 0.91,0.83 1.44,2.01 1.44,3.66 0,1.66 -0.51,3.02 -1.47,3.96 -0.95,0.95 -2.52,1.47 -4.5,1.47 -0.94,0 -1.72,-0.05 -2.39,-0.12 v -10.03 z m 1.32,9 c 0.33,0.06 0.82,0.08 1.33,0.08 2.81,0 4.34,-1.57 4.34,-4.32 0.02,-2.4 -1.34,-3.93 -4.12,-3.93 -0.68,0 -1.19,0.06 -1.54,0.14 v 8.03 z"
   id="path12288"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 192.37,305.57 c 0,3.5 -2.13,5.36 -4.73,5.36 -2.69,0 -4.58,-2.08 -4.58,-5.17 0,-3.23 2.01,-5.35 4.73,-5.35 2.78,0.01 4.58,2.14 4.58,5.16 z m -7.9,0.16 c 0,2.17 1.18,4.12 3.25,4.12 2.08,0 3.26,-1.92 3.26,-4.23 0,-2.02 -1.06,-4.14 -3.25,-4.14 -2.18,0.01 -3.26,2.02 -3.26,4.25 z"
   id="path12290"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 193.87,300.72 c 0.66,-0.14 1.62,-0.21 2.52,-0.21 1.4,0 2.31,0.26 2.95,0.83 0.51,0.45 0.8,1.15 0.8,1.93 0,1.34 -0.85,2.24 -1.92,2.6 v 0.04 c 0.79,0.27 1.25,1 1.5,2.05 0.33,1.42 0.57,2.4 0.79,2.79 h -1.36 c -0.17,-0.29 -0.39,-1.16 -0.68,-2.43 -0.3,-1.4 -0.85,-1.93 -2.04,-1.98 h -1.24 v 4.41 h -1.31 v -10.03 z m 1.31,4.64 h 1.34 c 1.4,0 2.3,-0.77 2.3,-1.93 0,-1.31 -0.95,-1.89 -2.34,-1.9 -0.63,0 -1.09,0.06 -1.3,0.12 z"
   id="path12292"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 204.71,309.16 c 0.59,0.36 1.45,0.67 2.36,0.67 1.34,0 2.13,-0.71 2.13,-1.74 0,-0.95 -0.54,-1.5 -1.92,-2.02 -1.66,-0.59 -2.69,-1.45 -2.69,-2.89 0,-1.59 1.31,-2.76 3.29,-2.76 1.04,0 1.8,0.24 2.25,0.5 l -0.36,1.07 c -0.33,-0.18 -1.01,-0.48 -1.93,-0.48 -1.39,0 -1.92,0.83 -1.92,1.53 0,0.95 0.62,1.42 2.02,1.96 1.72,0.66 2.6,1.5 2.6,2.99 0,1.57 -1.16,2.93 -3.56,2.93 -0.98,0 -2.05,-0.29 -2.6,-0.65 z"
   id="path12294"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 213.23,300.58 v 10.18 h -1.31 v -10.18 z"
   id="path12296"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 220.52,305.99 h -3.96 v 3.67 h 4.41 v 1.1 h -5.73 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 z"
   id="path12298"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 230.92,306.29 c -0.08,-1.42 -0.17,-3.13 -0.15,-4.4 h -0.05 c -0.35,1.19 -0.77,2.46 -1.28,3.87 l -1.8,4.94 h -1 l -1.65,-4.85 c -0.48,-1.43 -0.89,-2.75 -1.18,-3.96 h -0.03 c -0.03,1.27 -0.11,2.98 -0.2,4.5 l -0.27,4.37 h -1.25 l 0.71,-10.18 h 1.68 l 1.74,4.92 c 0.42,1.25 0.77,2.37 1.03,3.43 h 0.05 c 0.26,-1.03 0.62,-2.15 1.07,-3.43 l 1.81,-4.92 h 1.68 l 0.63,10.18 h -1.28 z"
   id="path12300"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 234.24,300.7 c 0.63,-0.11 1.46,-0.2 2.52,-0.2 1.3,0 2.25,0.3 2.85,0.85 0.56,0.48 0.89,1.22 0.89,2.13 0,0.92 -0.27,1.65 -0.79,2.17 -0.7,0.74 -1.83,1.12 -3.11,1.12 -0.39,0 -0.75,-0.01 -1.06,-0.09 v 4.08 h -1.31 V 300.7 Z m 1.32,4.91 c 0.29,0.08 0.65,0.11 1.09,0.11 1.59,0 2.55,-0.77 2.55,-2.17 0,-1.34 -0.95,-1.99 -2.4,-1.99 -0.57,0 -1.01,0.04 -1.24,0.11 z"
   id="path12302"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 241.77,300.72 c 0.66,-0.14 1.62,-0.21 2.52,-0.21 1.4,0 2.31,0.26 2.95,0.83 0.51,0.45 0.8,1.15 0.8,1.93 0,1.34 -0.85,2.24 -1.92,2.6 v 0.04 c 0.79,0.27 1.25,1 1.5,2.05 0.33,1.42 0.57,2.4 0.79,2.79 h -1.36 c -0.17,-0.29 -0.39,-1.16 -0.68,-2.43 -0.3,-1.4 -0.85,-1.93 -2.04,-1.98 h -1.24 v 4.41 h -1.31 v -10.03 z m 1.31,4.64 h 1.34 c 1.41,0 2.3,-0.77 2.3,-1.93 0,-1.31 -0.95,-1.89 -2.34,-1.9 -0.63,0 -1.09,0.06 -1.3,0.12 z"
   id="path12304"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 254.97,305.99 h -3.96 v 3.67 h 4.41 v 1.1 h -5.72 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 v 1.09 z"
   id="path12306"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 260.03,300.7 c 0.63,-0.11 1.46,-0.2 2.52,-0.2 1.3,0 2.25,0.3 2.86,0.85 0.56,0.48 0.89,1.22 0.89,2.13 0,0.92 -0.27,1.65 -0.79,2.17 -0.7,0.74 -1.83,1.12 -3.11,1.12 -0.39,0 -0.75,-0.01 -1.06,-0.09 v 4.08 h -1.31 z m 1.31,4.91 c 0.29,0.08 0.65,0.11 1.09,0.11 1.59,0 2.55,-0.77 2.55,-2.17 0,-1.34 -0.95,-1.99 -2.4,-1.99 -0.57,0 -1.01,0.04 -1.24,0.11 z"
   id="path12308"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 276.48,305.57 c 0,3.5 -2.13,5.36 -4.73,5.36 -2.69,0 -4.58,-2.08 -4.58,-5.17 0,-3.23 2.01,-5.35 4.73,-5.35 2.78,0.01 4.58,2.14 4.58,5.16 z m -7.91,0.16 c 0,2.17 1.18,4.12 3.25,4.12 2.08,0 3.26,-1.92 3.26,-4.23 0,-2.02 -1.06,-4.14 -3.25,-4.14 -2.17,0.01 -3.26,2.02 -3.26,4.25 z"
   id="path12310"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 277.57,309.16 c 0.59,0.36 1.45,0.67 2.36,0.67 1.34,0 2.13,-0.71 2.13,-1.74 0,-0.95 -0.54,-1.5 -1.92,-2.02 -1.66,-0.59 -2.69,-1.45 -2.69,-2.89 0,-1.59 1.31,-2.76 3.29,-2.76 1.04,0 1.8,0.24 2.25,0.5 l -0.36,1.07 c -0.33,-0.18 -1.01,-0.48 -1.93,-0.48 -1.39,0 -1.92,0.83 -1.92,1.53 0,0.95 0.62,1.42 2.02,1.96 1.72,0.66 2.6,1.5 2.6,2.99 0,1.57 -1.16,2.93 -3.57,2.93 -0.98,0 -2.05,-0.29 -2.6,-0.65 z"
   id="path12312"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 286.09,300.58 v 10.18 h -1.31 v -10.18 z"
   id="path12314"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 290.13,301.7 h -3.1 v -1.12 h 7.54 v 1.12 h -3.11 v 9.06 h -1.33 z"
   id="path12316"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 296.82,300.58 v 10.18 h -1.31 v -10.18 z"
   id="path12318"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 301.11,310.76 -3.32,-10.18 h 1.42 l 1.59,5.01 c 0.44,1.38 0.82,2.61 1.09,3.81 h 0.03 c 0.29,-1.18 0.71,-2.46 1.16,-3.79 l 1.72,-5.03 h 1.41 l -3.64,10.18 z"
   id="path12320"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 315.47,305.57 c 0,3.5 -2.13,5.36 -4.73,5.36 -2.69,0 -4.58,-2.08 -4.58,-5.17 0,-3.23 2.01,-5.35 4.73,-5.35 2.79,0.01 4.58,2.14 4.58,5.16 z m -7.9,0.16 c 0,2.17 1.18,4.12 3.25,4.12 2.08,0 3.26,-1.92 3.26,-4.23 0,-2.02 -1.06,-4.14 -3.25,-4.14 -2.17,0.01 -3.26,2.02 -3.26,4.25 z"
   id="path12322"
   style="fill:#232626;fill-opacity:1" />
		</g>
	</g><g
       id="g12422"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline;fill:#232626;fill-opacity:1">
		<g
   id="g12420"
   style="fill:#232626;fill-opacity:1">
			<path
   class="st0"
   d="m 98.39,257.19 v -0.82 l 1.04,-1.01 c 2.51,-2.39 3.64,-3.66 3.66,-5.14 0,-1 -0.48,-1.92 -1.95,-1.92 -0.89,0 -1.63,0.45 -2.08,0.83 l -0.42,-0.94 c 0.68,-0.57 1.65,-1 2.78,-1 2.11,0 3.01,1.45 3.01,2.86 0,1.81 -1.31,3.28 -3.38,5.27 l -0.79,0.73 v 0.03 h 4.41 v 1.1 h -6.28 z"
   id="path12372"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 112.2,252.18 c 0,3.34 -1.24,5.18 -3.41,5.18 -1.92,0 -3.22,-1.8 -3.25,-5.05 0,-3.29 1.42,-5.11 3.41,-5.11 2.07,0.01 3.25,1.85 3.25,4.98 z m -5.34,0.15 c 0,2.55 0.79,4 1.99,4 1.36,0 2.01,-1.59 2.01,-4.09 0,-2.42 -0.62,-4 -1.99,-4 -1.16,-0.01 -2.01,1.41 -2.01,4.09 z"
   id="path12374"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 119.92,252.18 c 0,3.34 -1.24,5.18 -3.41,5.18 -1.92,0 -3.22,-1.8 -3.25,-5.05 0,-3.29 1.42,-5.11 3.41,-5.11 2.07,0.01 3.25,1.85 3.25,4.98 z m -5.33,0.15 c 0,2.55 0.79,4 1.99,4 1.36,0 2.01,-1.59 2.01,-4.09 0,-2.42 -0.62,-4 -1.99,-4 -1.16,-0.01 -2.01,1.41 -2.01,4.09 z"
   id="path12376"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 123.4,257.19 -3.32,-10.18 h 1.42 l 1.59,5.02 c 0.44,1.38 0.82,2.61 1.09,3.81 h 0.03 c 0.29,-1.18 0.71,-2.46 1.16,-3.79 l 1.72,-5.03 h 1.4 l -3.64,10.18 h -1.45 z"
   id="path12378"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 130.21,253.99 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.4 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12380"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 144.24,256.86 c -0.48,0.24 -1.45,0.48 -2.69,0.48 -2.87,0 -5.03,-1.81 -5.03,-5.15 0,-3.19 2.16,-5.35 5.32,-5.35 1.27,0 2.07,0.27 2.42,0.45 l -0.32,1.07 c -0.5,-0.24 -1.21,-0.42 -2.05,-0.42 -2.39,0 -3.97,1.53 -3.97,4.2 0,2.49 1.44,4.09 3.91,4.09 0.8,0 1.62,-0.17 2.15,-0.42 z"
   id="path12382"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 169.42,248.49 h -3.75 l -0.38,2.52 c 0.23,-0.03 0.44,-0.06 0.8,-0.06 0.76,0 1.51,0.17 2.11,0.53 0.77,0.44 1.4,1.28 1.4,2.52 0,1.92 -1.53,3.35 -3.66,3.35 -1.07,0 -1.98,-0.3 -2.45,-0.6 l 0.33,-1.01 c 0.41,0.24 1.21,0.54 2.1,0.54 1.25,0 2.33,-0.82 2.33,-2.13 -0.02,-1.27 -0.86,-2.18 -2.83,-2.18 -0.56,0 -1,0.06 -1.36,0.11 l 0.63,-4.71 h 4.7 v 1.12 z"
   id="path12384"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 177.52,246.71 c -1.1,1.48 -1.9,3.44 -1.9,6.18 0,2.69 0.83,4.62 1.9,6.13 h -1.04 c -0.95,-1.25 -1.98,-3.19 -1.98,-6.13 0.02,-2.98 1.03,-4.91 1.98,-6.18 z"
   id="path12386"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 183.47,248.31 c -0.27,-0.02 -0.62,0 -1,0.06 -2.08,0.35 -3.19,1.87 -3.41,3.49 h 0.05 c 0.47,-0.62 1.28,-1.13 2.37,-1.13 1.74,0 2.96,1.25 2.96,3.17 0,1.8 -1.22,3.46 -3.26,3.46 -2.1,0 -3.47,-1.63 -3.47,-4.18 0,-1.93 0.69,-3.46 1.66,-4.43 0.82,-0.8 1.9,-1.3 3.14,-1.45 0.39,-0.06 0.73,-0.08 0.97,-0.08 v 1.09 z m -0.37,5.66 c 0,-1.4 -0.8,-2.25 -2.02,-2.25 -0.8,0 -1.54,0.5 -1.9,1.21 -0.09,0.15 -0.15,0.35 -0.15,0.59 0.03,1.62 0.77,2.81 2.16,2.81 1.14,0 1.91,-0.95 1.91,-2.36 z"
   id="path12388"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 191.97,252.18 c 0,3.34 -1.24,5.18 -3.41,5.18 -1.92,0 -3.22,-1.8 -3.25,-5.05 0,-3.29 1.42,-5.11 3.41,-5.11 2.07,0.01 3.25,1.85 3.25,4.98 z m -5.33,0.15 c 0,2.55 0.79,4 1.99,4 1.36,0 2.01,-1.59 2.01,-4.09 0,-2.42 -0.62,-4 -1.99,-4 -1.17,-0.01 -2.01,1.41 -2.01,4.09 z"
   id="path12390"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 192.29,259.02 c 1.07,-1.5 1.9,-3.46 1.9,-6.18 0,-2.7 -0.82,-4.64 -1.9,-6.13 h 1.03 c 0.97,1.24 1.98,3.17 1.98,6.15 0,2.96 -1.01,4.88 -1.98,6.16 z"
   id="path12392"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 201.97,253.99 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.4 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12394"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 232.86,248.31 c -0.27,-0.02 -0.62,0 -1,0.06 -2.08,0.35 -3.19,1.87 -3.41,3.49 h 0.05 c 0.47,-0.62 1.28,-1.13 2.37,-1.13 1.74,0 2.96,1.25 2.96,3.17 0,1.8 -1.22,3.46 -3.26,3.46 -2.1,0 -3.47,-1.63 -3.47,-4.18 0,-1.93 0.7,-3.46 1.66,-4.43 0.82,-0.8 1.9,-1.3 3.14,-1.45 0.39,-0.06 0.73,-0.08 0.97,-0.08 v 1.09 z m -0.38,5.66 c 0,-1.4 -0.8,-2.25 -2.02,-2.25 -0.8,0 -1.54,0.5 -1.9,1.21 -0.09,0.15 -0.15,0.35 -0.15,0.59 0.03,1.62 0.77,2.81 2.16,2.81 1.14,0 1.91,-0.95 1.91,-2.36 z"
   id="path12396"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 241.35,252.18 c 0,3.34 -1.24,5.18 -3.41,5.18 -1.92,0 -3.22,-1.8 -3.25,-5.05 0,-3.29 1.42,-5.11 3.41,-5.11 2.07,0.01 3.25,1.85 3.25,4.98 z m -5.33,0.15 c 0,2.55 0.79,4 1.99,4 1.36,0 2.01,-1.59 2.01,-4.09 0,-2.42 -0.62,-4 -1.99,-4 -1.17,-0.01 -2.01,1.41 -2.01,4.09 z"
   id="path12398"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 244.23,247.01 v 4.26 h 4.92 v -4.26 h 1.33 v 10.18 h -1.33 v -4.77 h -4.92 v 4.77 h -1.31 v -10.18 z"
   id="path12400"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 251.65,256.42 5.65,-8.26 v -0.04 h -5.17 v -1.1 H 259 v 0.8 l -5.62,8.23 v 0.05 h 5.7 v 1.1 h -7.43 z"
   id="path12402"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 285.96,256.86 c -0.48,0.24 -1.45,0.48 -2.69,0.48 -2.87,0 -5.03,-1.81 -5.03,-5.15 0,-3.19 2.16,-5.35 5.32,-5.35 1.27,0 2.07,0.27 2.42,0.45 l -0.32,1.07 c -0.5,-0.24 -1.21,-0.42 -2.05,-0.42 -2.39,0 -3.97,1.53 -3.97,4.2 0,2.49 1.44,4.09 3.91,4.09 0.8,0 1.62,-0.17 2.15,-0.42 z"
   id="path12404"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 287.17,247.01 h 1.31 v 9.08 h 4.35 v 1.1 h -5.66 z"
   id="path12406"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 295.97,253.99 -1.06,3.2 h -1.36 l 3.46,-10.18 h 1.59 l 3.47,10.18 h -1.4 l -1.09,-3.2 z m 3.34,-1.03 -1,-2.93 c -0.23,-0.67 -0.38,-1.27 -0.53,-1.86 h -0.03 c -0.15,0.6 -0.32,1.22 -0.51,1.84 l -1,2.95 z"
   id="path12408"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 303.23,255.59 c 0.59,0.36 1.45,0.66 2.36,0.66 1.34,0 2.13,-0.71 2.13,-1.74 0,-0.95 -0.54,-1.5 -1.92,-2.02 -1.66,-0.59 -2.69,-1.45 -2.69,-2.89 0,-1.59 1.31,-2.76 3.29,-2.76 1.04,0 1.8,0.24 2.25,0.5 l -0.36,1.07 c -0.33,-0.18 -1.01,-0.48 -1.93,-0.48 -1.39,0 -1.92,0.83 -1.92,1.53 0,0.95 0.62,1.42 2.02,1.96 1.72,0.66 2.6,1.5 2.6,2.99 0,1.57 -1.16,2.93 -3.57,2.93 -0.98,0 -2.05,-0.29 -2.6,-0.65 z"
   id="path12410"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 315.72,252.42 h -3.96 v 3.67 h 4.41 v 1.1 h -5.72 v -10.18 h 5.5 v 1.1 h -4.18 v 3.22 h 3.96 v 1.09 z"
   id="path12412"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 323.19,248.61 h -0.03 l -1.71,0.92 -0.26,-1.01 2.14,-1.15 h 1.13 v 9.82 h -1.28 v -8.58 z"
   id="path12414"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 327.13,256.41 c 0,-0.56 0.38,-0.95 0.91,-0.95 0.53,0 0.89,0.39 0.89,0.95 0,0.54 -0.35,0.95 -0.91,0.95 -0.53,0 -0.89,-0.41 -0.89,-0.95 z"
   id="path12416"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 336.41,252.18 c 0,3.34 -1.24,5.18 -3.41,5.18 -1.92,0 -3.22,-1.8 -3.25,-5.05 0,-3.29 1.42,-5.11 3.41,-5.11 2.07,0.01 3.25,1.85 3.25,4.98 z m -5.33,0.15 c 0,2.55 0.79,4 1.99,4 1.36,0 2.01,-1.59 2.01,-4.09 0,-2.42 -0.62,-4 -1.99,-4 -1.17,-0.01 -2.01,1.41 -2.01,4.09 z"
   id="path12418"
   style="fill:#232626;fill-opacity:1" />
		</g>
	</g><path
       class="st0"
       d="m 103.28973,124.54273 c 0.0705,0.044 0.17319,0.0819 0.28189,0.0819 0.16008,0 0.25445,-0.0868 0.25445,-0.21274 0,-0.11616 -0.0645,-0.1834 -0.22935,-0.24697 -0.19829,-0.0721 -0.32133,-0.17729 -0.32133,-0.35214 0,-0.19439 0.15649,-0.33745 0.393,-0.33745 0.12422,0 0.21501,0.0294 0.26877,0.0611 l -0.043,0.13082 c -0.0394,-0.022 -0.12066,-0.0586 -0.23054,-0.0586 -0.16604,0 -0.22936,0.10147 -0.22936,0.18706 0,0.11616 0.0741,0.17362 0.24131,0.23965 0.20545,0.0819 0.31057,0.18339 0.31057,0.36556 0,0.19196 -0.13856,0.35825 -0.42526,0.35825 -0.11705,0 -0.24487,-0.0355 -0.31058,-0.0795 z"
       id="path12424"
       style="display:inline;fill:#333333;stroke-width:0.120852" /><path
       class="st0"
       d="m 104.78171,124.15515 h -0.47304 v 0.44872 h 0.52679 v 0.13449 h -0.68447 v -1.24466 h 0.657 v 0.13448 h -0.49932 v 0.39371 h 0.47304 z"
       id="path12426"
       style="display:inline;fill:#333333;stroke-width:0.120852" /><path
       class="st0"
       d="m 104.99075,123.51081 c 0.08,-0.0171 0.19352,-0.0257 0.30101,-0.0257 0.16725,0 0.27595,0.0317 0.35239,0.10148 0.0609,0.055 0.0956,0.14061 0.0956,0.23597 0,0.16385 -0.10154,0.27387 -0.22935,0.3179 v 0.006 c 0.0943,0.033 0.14932,0.12227 0.17918,0.25064 0.0394,0.17362 0.0681,0.29345 0.0943,0.34113 h -0.16245 c -0.0203,-0.0355 -0.0466,-0.14183 -0.0813,-0.29711 -0.0358,-0.17239 -0.10153,-0.23598 -0.24369,-0.24208 h -0.14813 v 0.53919 h -0.15648 v -1.22754 z m 0.15768,0.5661 h 0.16006 c 0.16723,0 0.27474,-0.0942 0.27474,-0.23598 0,-0.16017 -0.11348,-0.23108 -0.27953,-0.23231 -0.0753,0 -0.1302,0.006 -0.15528,0.0146 z"
       id="path12428"
       style="display:inline;fill:#333333;stroke-width:0.120852" /><path
       class="st0"
       d="m 106.09807,123.4937 v 1.24466 h -0.15648 v -1.24466 z"
       id="path12430"
       style="display:inline;fill:#333333;stroke-width:0.120852" /><path
       class="st0"
       d="m 106.9701,124.15515 h -0.47303 v 0.44872 h 0.52679 v 0.13449 h -0.68448 v -1.24466 h 0.657 v 0.13448 h -0.49931 v 0.39371 h 0.47303 z"
       id="path12432"
       style="display:inline;fill:#333333;stroke-width:0.120852" /><path
       class="st0"
       d="m 107.14808,123.99253 c 0,-0.0685 0.0454,-0.11614 0.1087,-0.11614 0.0633,0 0.10512,0.0476 0.10633,0.11614 0,0.0647 -0.0419,0.11495 -0.10871,0.11495 -0.0621,0 -0.10632,-0.0502 -0.10632,-0.11495 z m 0,0.65168 c 0,-0.0685 0.0454,-0.11615 0.1087,-0.11615 0.0633,0 0.10512,0.0476 0.10633,0.11615 0,0.0647 -0.0419,0.11493 -0.10871,0.11493 -0.0621,0 -0.10632,-0.0502 -0.10632,-0.11493 z"
       id="path12434"
       style="display:inline;fill:#333333;stroke-width:0.120852" /><g
       id="g12550"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline;fill:#232626;fill-opacity:1">
		<g
   id="g12548"
   style="fill:#232626;fill-opacity:1">
			<path
   class="st0"
   d="m 233.46,74.28 v 8.17 h -1.05 v -8.17 z"
   id="path12504"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 239.32,78.61 h -3.17 v 2.94 h 3.54 v 0.88 h -4.59 v -8.17 h 4.41 v 0.88 h -3.36 v 2.58 h 3.17 z"
   id="path12506"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 246.45,82.18 c -0.39,0.19 -1.16,0.39 -2.16,0.39 -2.3,0 -4.03,-1.45 -4.03,-4.13 0,-2.56 1.73,-4.29 4.26,-4.29 1.02,0 1.66,0.22 1.94,0.36 l -0.25,0.86 c -0.4,-0.19 -0.97,-0.34 -1.65,-0.34 -1.91,0 -3.19,1.22 -3.19,3.37 0,2 1.15,3.28 3.14,3.28 0.64,0 1.3,-0.13 1.72,-0.34 z"
   id="path12508"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 251.5,75.32 c -0.22,-0.01 -0.5,0 -0.8,0.05 -1.67,0.28 -2.56,1.5 -2.74,2.8 H 248 c 0.38,-0.5 1.03,-0.91 1.9,-0.91 1.39,0 2.38,1.01 2.38,2.54 0,1.44 -0.98,2.77 -2.62,2.77 -1.68,0 -2.79,-1.31 -2.79,-3.36 0,-1.55 0.56,-2.77 1.33,-3.55 0.65,-0.64 1.53,-1.04 2.52,-1.16 0.32,-0.05 0.58,-0.06 0.78,-0.06 z m -0.3,4.54 c 0,-1.13 -0.64,-1.81 -1.62,-1.81 -0.64,0 -1.24,0.4 -1.53,0.97 -0.07,0.12 -0.12,0.28 -0.12,0.47 0.02,1.3 0.62,2.25 1.73,2.25 0.92,0.01 1.54,-0.75 1.54,-1.88 z"
   id="path12510"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 252.77,82.44 v -0.65 l 0.84,-0.81 c 2.01,-1.91 2.92,-2.93 2.93,-4.12 0,-0.8 -0.39,-1.54 -1.56,-1.54 -0.71,0 -1.31,0.36 -1.67,0.67 l -0.34,-0.75 c 0.54,-0.46 1.32,-0.8 2.23,-0.8 1.7,0 2.41,1.16 2.41,2.29 0,1.45 -1.05,2.63 -2.71,4.23 l -0.63,0.58 v 0.02 h 3.54 v 0.88 z"
   id="path12512"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 263.86,78.42 c 0,2.68 -0.99,4.16 -2.74,4.16 -1.54,0 -2.58,-1.44 -2.6,-4.05 0,-2.64 1.14,-4.1 2.74,-4.1 1.66,0.01 2.6,1.48 2.6,3.99 z m -4.28,0.12 c 0,2.05 0.63,3.21 1.6,3.21 1.09,0 1.61,-1.27 1.61,-3.28 0,-1.94 -0.5,-3.21 -1.6,-3.21 -0.93,0 -1.61,1.14 -1.61,3.28 z"
   id="path12514"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 269.26,75.32 c -0.22,-0.01 -0.5,0 -0.8,0.05 -1.67,0.28 -2.56,1.5 -2.74,2.8 h 0.04 c 0.38,-0.5 1.03,-0.91 1.9,-0.91 1.39,0 2.38,1.01 2.38,2.54 0,1.44 -0.98,2.77 -2.62,2.77 -1.68,0 -2.79,-1.31 -2.79,-3.36 0,-1.55 0.56,-2.77 1.33,-3.55 0.65,-0.64 1.53,-1.04 2.52,-1.16 0.32,-0.05 0.58,-0.06 0.78,-0.06 z m -0.3,4.54 c 0,-1.13 -0.64,-1.81 -1.62,-1.81 -0.64,0 -1.24,0.4 -1.53,0.97 -0.07,0.12 -0.12,0.28 -0.12,0.47 0.02,1.3 0.62,2.25 1.73,2.25 0.92,0.01 1.54,-0.75 1.54,-1.88 z"
   id="path12516"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 270.53,82.44 v -0.65 l 0.84,-0.81 c 2.01,-1.91 2.92,-2.93 2.93,-4.12 0,-0.8 -0.39,-1.54 -1.56,-1.54 -0.71,0 -1.31,0.36 -1.67,0.67 l -0.34,-0.75 c 0.54,-0.46 1.32,-0.8 2.23,-0.8 1.7,0 2.41,1.16 2.41,2.29 0,1.45 -1.05,2.63 -2.71,4.23 l -0.63,0.58 v 0.02 h 3.54 v 0.88 z"
   id="path12518"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 279.2,77.86 v 0.79 h -2.99 v -0.79 z"
   id="path12520"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 281.35,75.56 h -0.02 l -1.37,0.74 -0.21,-0.81 1.72,-0.92 h 0.91 v 7.88 h -1.03 z"
   id="path12522"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 286.08,75.56 h -0.02 l -1.37,0.74 -0.21,-0.81 1.72,-0.92 h 0.91 v 7.88 h -1.03 z"
   id="path12524"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 298.63,74.28 v 8.17 h -1.05 v -8.17 z"
   id="path12526"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 304.49,78.61 h -3.17 v 2.94 h 3.54 v 0.88 h -4.59 v -8.17 h 4.41 v 0.88 h -3.36 v 2.58 h 3.17 z"
   id="path12528"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 311.62,82.18 c -0.39,0.19 -1.16,0.39 -2.16,0.39 -2.3,0 -4.03,-1.45 -4.03,-4.13 0,-2.56 1.73,-4.29 4.26,-4.29 1.02,0 1.66,0.22 1.94,0.36 l -0.25,0.86 c -0.4,-0.19 -0.97,-0.34 -1.65,-0.34 -1.91,0 -3.19,1.22 -3.19,3.37 0,2 1.15,3.28 3.14,3.28 0.64,0 1.3,-0.13 1.72,-0.34 z"
   id="path12530"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 316.67,75.32 c -0.22,-0.01 -0.5,0 -0.8,0.05 -1.67,0.28 -2.56,1.5 -2.74,2.8 h 0.04 c 0.38,-0.5 1.03,-0.91 1.9,-0.91 1.39,0 2.38,1.01 2.38,2.54 0,1.44 -0.98,2.77 -2.62,2.77 -1.68,0 -2.79,-1.31 -2.79,-3.36 0,-1.55 0.56,-2.77 1.33,-3.55 0.65,-0.64 1.53,-1.04 2.52,-1.16 0.32,-0.05 0.58,-0.06 0.78,-0.06 z m -0.3,4.54 c 0,-1.13 -0.64,-1.81 -1.62,-1.81 -0.64,0 -1.24,0.4 -1.53,0.97 -0.07,0.12 -0.12,0.28 -0.12,0.47 0.02,1.3 0.62,2.25 1.73,2.25 0.92,0.01 1.54,-0.75 1.54,-1.88 z"
   id="path12532"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 317.94,82.44 v -0.65 l 0.84,-0.81 c 2.01,-1.91 2.92,-2.93 2.93,-4.12 0,-0.8 -0.39,-1.54 -1.56,-1.54 -0.71,0 -1.31,0.36 -1.67,0.67 l -0.34,-0.75 c 0.54,-0.46 1.32,-0.8 2.23,-0.8 1.7,0 2.41,1.16 2.41,2.29 0,1.45 -1.05,2.63 -2.71,4.23 l -0.63,0.58 v 0.02 h 3.54 v 0.88 z"
   id="path12534"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 329.03,78.42 c 0,2.68 -0.99,4.16 -2.74,4.16 -1.54,0 -2.58,-1.44 -2.6,-4.05 0,-2.64 1.14,-4.1 2.74,-4.1 1.66,0.01 2.6,1.48 2.6,3.99 z m -4.27,0.12 c 0,2.05 0.63,3.21 1.6,3.21 1.09,0 1.61,-1.27 1.61,-3.28 0,-1.94 -0.5,-3.21 -1.6,-3.21 -0.94,0 -1.61,1.14 -1.61,3.28 z"
   id="path12536"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 334.38,75.46 h -3 l -0.3,2.02 c 0.18,-0.02 0.35,-0.05 0.64,-0.05 0.61,0 1.21,0.13 1.7,0.42 0.62,0.35 1.13,1.03 1.13,2.02 0,1.54 -1.22,2.69 -2.93,2.69 -0.86,0 -1.59,-0.24 -1.96,-0.48 l 0.27,-0.81 c 0.33,0.19 0.97,0.44 1.68,0.44 1.01,0 1.87,-0.65 1.87,-1.71 -0.01,-1.02 -0.69,-1.74 -2.27,-1.74 -0.45,0 -0.8,0.05 -1.09,0.08 l 0.51,-3.78 h 3.77 v 0.9 z"
   id="path12538"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 335.58,81.22 c 0.3,0.19 1.01,0.5 1.74,0.5 1.37,0 1.79,-0.87 1.78,-1.53 -0.01,-1.1 -1.01,-1.58 -2.04,-1.58 h -0.59 v -0.8 h 0.59 c 0.78,0 1.76,-0.4 1.76,-1.33 0,-0.63 -0.4,-1.19 -1.38,-1.19 -0.63,0 -1.24,0.28 -1.57,0.52 l -0.28,-0.78 c 0.41,-0.3 1.21,-0.61 2.06,-0.61 1.55,0 2.25,0.92 2.25,1.88 0,0.81 -0.48,1.5 -1.45,1.85 v 0.02 c 0.97,0.19 1.76,0.92 1.76,2.02 0,1.26 -0.98,2.36 -2.87,2.36 -0.88,0 -1.66,-0.28 -2.05,-0.53 z"
   id="path12540"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 343.98,77.86 v 0.79 h -2.99 v -0.79 z"
   id="path12542"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 343.8,82.44 v -0.65 l 0.84,-0.81 c 2.01,-1.91 2.92,-2.93 2.93,-4.12 0,-0.8 -0.39,-1.54 -1.56,-1.54 -0.71,0 -1.31,0.36 -1.67,0.67 L 344,75.23 c 0.54,-0.46 1.32,-0.8 2.23,-0.8 1.7,0 2.41,1.16 2.41,2.29 0,1.45 -1.05,2.63 -2.71,4.23 l -0.63,0.58 v 0.02 h 3.54 v 0.88 h -5.04 z"
   id="path12544"
   style="fill:#232626;fill-opacity:1" />
			<path
   class="st0"
   d="m 351.52,75.56 h -0.02 l -1.37,0.74 -0.21,-0.81 1.72,-0.92 h 0.91 v 7.88 h -1.03 z"
   id="path12546"
   style="fill:#232626;fill-opacity:1" />
		</g>
	</g><g
       id="g12566"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline;opacity:0.99;fill:#333333">
		<path
   class="st0"
   d="m 112.09,333.05 c 1.21,-0.07 2.8,-0.13 4.81,-0.13 2.48,0 4.29,0.2 5.45,0.56 1.07,0.32 1.7,0.81 1.7,1.41 0,0.61 -0.52,1.09 -1.5,1.43 -1.33,0.49 -3.49,0.74 -5.94,0.74 -0.75,0 -1.44,-0.01 -2.02,-0.06 v 2.69 h -2.51 v -6.64 z m 2.51,3.24 c 0.55,0.05 1.24,0.07 2.08,0.07 3.03,0 4.87,-0.51 4.87,-1.43 0,-0.89 -1.82,-1.32 -4.58,-1.32 -1.1,0 -1.93,0.03 -2.36,0.07 v 2.61 z"
   id="path12562"
   style="fill:#333333" />
		<path
   class="st0"
   d="m 128.11,337.58 -2.02,2.11 h -2.59 l 6.6,-6.72 h 3.03 l 6.63,6.72 h -2.68 L 135,337.58 Z m 6.37,-0.68 -1.9,-1.93 c -0.43,-0.44 -0.72,-0.84 -1.01,-1.23 h -0.06 c -0.29,0.4 -0.6,0.81 -0.98,1.22 l -1.9,1.94 z"
   id="path12564"
   style="fill:#333333" />
	</g><g
       id="g12605"
       transform="matrix(0.11945477,0,0,0.12226541,90.972758,90.032062)"
       style="display:inline;fill:#333333">
		<g
   id="g12597"
   style="fill:#333333">
			<path
   class="st0"
   d="m 315.79,290.1 c -1.86,0 -3.37,-1.51 -3.37,-3.37 0,-1.86 1.51,-3.37 3.37,-3.37 1.86,0 3.37,1.51 3.37,3.37 0,1.86 -1.51,3.37 -3.37,3.37 z m 0,-5.74 c -1.31,0 -2.37,1.06 -2.37,2.37 0,1.31 1.06,2.37 2.37,2.37 1.31,0 2.37,-1.06 2.37,-2.37 0,-1.31 -1.07,-2.37 -2.37,-2.37 z"
   id="path12593"
   style="fill:#333333" />
			<rect
   x="315.29001"
   y="271.5"
   class="st0"
   width="1"
   height="12.36"
   id="rect12595"
   style="fill:#333333" />
		</g>
		<g
   id="g12603"
   style="fill:#333333">
			<path
   class="st0"
   d="M 343.06,291.85 H 322.21 V 271 h 20.85 z m -19.85,-1 h 18.85 V 272 h -18.85 z"
   id="path12599"
   style="fill:#333333" />
			<path
   class="st0"
   d="M 339.86,288.65 H 325.41 V 274.2 h 14.45 z m -13.45,-1 h 12.45 V 275.2 h -12.45 z"
   id="path12601"
   style="fill:#333333" />
		</g>
	</g><path
       class="st0"
       d="m 108.48838,130.88709 v -0.0624 c -0.52919,-0.19195 -1.45735,-0.31789 -2.51453,-0.31789 -1.05716,0 -1.98652,0.12594 -2.5145,0.31789 v 0.73114 c 0.52798,0.19075 1.45734,0.31667 2.5145,0.31667 1.05718,0 1.98534,-0.12592 2.51453,-0.31667 v -0.0636 h 0.46468 v 0.49762 c -0.14215,0.0489 -0.29745,0.0929 -0.46468,0.13205 -0.68687,0.16627 -1.56247,0.26531 -2.51453,0.26531 -0.95204,0 -1.82645,-0.099 -2.5145,-0.26653 -0.16604,-0.0379 -0.32133,-0.0819 -0.46468,-0.13083 v -1.60045 c 0.14335,-0.0476 0.29864,-0.0916 0.46468,-0.13204 0.68805,-0.16629 1.56246,-0.26532 2.5145,-0.26532 0.95206,0 1.82766,0.099 2.51453,0.26532 0.16723,0.0404 0.32253,0.0844 0.46468,0.13204 v 0.49763 z"
       id="path12607"
       style="display:inline;fill:#232626;fill-opacity:1;stroke-width:0.120852" /><rect
       style="display:inline;opacity:0.999;fill:#778d92;fill-opacity:1;stroke-width:0.18499"
       id="rect12943"
       width="10.890945"
       height="1.2225959"
       x="120.41348"
       y="135.62767" /><rect
       style="display:inline;opacity:0.999;fill:#778d92;fill-opacity:1;stroke-width:0.19824"
       id="rect12943-0"
       width="12.507022"
       height="1.2225959"
       x="109.90895"
       y="123.50956" /><rect
       style="display:inline;opacity:0.999;fill:#778d92;fill-opacity:1;stroke-width:0.115095"
       id="rect12943-0-7"
       width="4.2158499"
       height="1.2225959"
       x="105.94598"
       y="113.44796" /><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="129.62694"
       y="105.25797"
       id="L1_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L1"
         x="129.62694"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="126.49684"
       y="105.25797"
       id="L2_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L2"
         x="126.49684"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="123.36678"
       y="105.25797"
       id="L3_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L3"
         x="123.36678"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="122.29749"
       y="105.15269"
       id="text13026-9"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="tspan13024-0"
         x="122.29749"
         y="105.15269"
         style="stroke-width:0.120852">.</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="119.83794"
       y="105.25797"
       id="L4_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L4"
         x="119.83794"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="116.70789"
       y="105.25797"
       id="L5_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L5"
         x="116.70789"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.99523px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="113.57777"
       y="105.25797"
       id="L6_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L6"
         x="113.57777"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="110.44772"
       y="105.25797"
       id="L7_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L7"
         x="110.44772"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="107.31761"
       y="105.25797"
       id="L8_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L8"
         x="107.31761"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.83406px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke:none;stroke-width:0.120852"
       x="104.18755"
       y="105.25797"
       id="L9_"
       transform="scale(0.98843902,1.0116962)"><tspan
         sodipodi:role="line"
         id="L9"
         x="104.18755"
         y="105.25797"
         style="font-size:4.99523px;fill:#232626;fill-opacity:1;stroke-width:0.120852">0</tspan></text><text
       xml:space="preserve"
       style="font-size:2.0486px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke-width:0.256075"
       x="123.81533"
       y="116.71116"
       id="text14033"
       transform="scale(0.97858398,1.0218847)"><tspan
         sodipodi:role="line"
         id="tspan14031"
         x="123.81533"
         y="116.71116"
         style="font-size:2.0486px;fill:#232626;fill-opacity:1;stroke-width:0.256075">XXXX</tspan></text><text
       xml:space="preserve"
       style="font-size:1.70717px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke-width:0.256075"
       x="129.81123"
       y="116.55976"
       id="text14033-4"
       transform="scale(0.97858398,1.0218847)"><tspan
         sodipodi:role="line"
         id="tspan14031-3"
         x="129.81123"
         y="116.55976"
         style="font-size:1.70717px;fill:#232626;fill-opacity:1;stroke-width:0.256075">imp/KWh</tspan></text><text
       xml:space="preserve"
       style="font-size:1.70717px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke-width:0.256075"
       x="112.82973"
       y="112.22384"
       id="text14033-4-8"
       transform="scale(0.97858398,1.0218847)"><tspan
         sodipodi:role="line"
         id="tspan14031-3-8"
         x="112.82973"
         y="112.22384"
         style="font-size:1.70717px;fill:#232626;fill-opacity:1;stroke-width:0.256075">PULSE RC4 E - TECA</tspan></text><text
       xml:space="preserve"
       style="font-size:2.0486px;line-height:1.25;font-family:sans-serif;display:inline;fill:#232626;fill-opacity:1;stroke-width:0.256075"
       x="112.40362"
       y="98.057228"
       id="text14033-3-5"
       inkscape:transform-center-x="4.1169161"
       inkscape:transform-center-y="22.811336"
       transform="scale(0.97858398,1.0218847)"><tspan
         sodipodi:role="line"
         id="tspan14031-8-2"
         x="112.40362"
         y="98.057228"
         style="font-size:2.0486px;fill:#232626;fill-opacity:1;stroke-width:0.256075">kWh</tspan></text><path
       d="m 151.20846,145.246 c -0.0815,0.0339 -0.14891,0.1433 -0.14891,0.24537 v 0.0898 l -0.80834,0.009 c -0.77571,0.009 -0.81532,0.0139 -0.94109,0.0631 -0.24464,0.10209 -0.41233,0.30116 -0.51016,0.60238 -0.0441,0.14096 -0.0466,0.22105 -0.0466,4.15132 0,3.93029 9.3e-4,4.0104 0.0466,4.15134 0.0977,0.30116 0.26552,0.50035 0.51016,0.60238 l 0.13043,0.0558 h 2.56239 2.5624 l 0.13281,-0.0558 c 0.1864,-0.0777 0.3494,-0.2405 0.44256,-0.43726 l 0.0768,-0.16273 0.007,-4.10272 c 0.005,-4.53026 0.0153,-4.23148 -0.13993,-4.4744 -0.0417,-0.0654 -0.12805,-0.15786 -0.19335,-0.20888 -0.21433,-0.16764 -0.23991,-0.1725 -1.06222,-0.1846 l -0.73847,-0.009 -0.0119,-0.10935 c -0.007,-0.0753 -0.03,-0.1311 -0.0724,-0.17492 l -0.0603,-0.0632 -0.84558,-0.006 c -0.46357,-8.9e-4 -0.86657,0.006 -0.89221,0.017 z m 3.35209,0.81132 c 0.0908,0.0387 0.21897,0.18462 0.24227,0.27693 0.0236,0.0851 0.0236,8.06215 0,8.14716 -0.0234,0.0922 -0.15128,0.23805 -0.24227,0.27692 -0.11195,0.0511 -5.00365,0.0511 -5.11551,0 -0.10023,-0.041 -0.21662,-0.18457 -0.24693,-0.2988 -0.0346,-0.12864 -0.0346,-7.9747 0,-8.10341 0.028,-0.1115 0.14688,-0.25505 0.2423,-0.2988 0.10448,-0.0484 5.01065,-0.0484 5.12014,0 z"
       id="Battery_Level"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1.73255;stroke-miterlimit:4;stroke-dasharray:none" /><path
       d="m 149.76687,147.27993 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50696 h -2.20826 -2.20831 z"
       id="bat_L5"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524;paint-order:stroke fill markers" /><path
       d="m 149.76687,148.85577 v 0.50696 h 2.20831 2.20826 v -0.50696 -0.50695 h -2.20826 -2.20831 z"
       id="bat_L4"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.0071252" /><path
       d="m 149.76687,150.36312 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L3"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712529" /><path
       d="m 149.76687,151.93901 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L2"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524" /><path
       d="m 149.76687,153.51488 v 0.50694 h 2.20831 2.20826 v -0.50694 -0.50693 h -2.20826 -2.20831 z"
       id="bat_L1"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712532" /><g
       id="g76451"
       style="display:inline;fill:#1a1a1a;fill-opacity:1;stroke-width:12.3402;stroke-dasharray:none"
       transform="matrix(0.30487124,0,0,0.321627,135.25231,209.40436)"><g
         id="sFraude"
         style="fill:#1a1a1a"><g
           id="g27831"
           style="fill:#1a1a1a"><g
             id="g31956"
             transform="matrix(0.07298313,0,0,0.06903844,38.558924,-350.55249)"
             style="fill:#1a1a1a"><g
               id="g31865"
               style="fill:#1a1a1a">
	<g
   id="g31863"
   style="fill:#1a1a1a">
		<path
   d="M 394.095,330.155 C 374.315,290.586 373.76,249.848 373.76,249.447 v -46.08 c 0,-64.93 -52.83,-117.76 -117.76,-117.76 -64.93,0 -117.76,52.83 -117.76,117.76 v 46.08 c 0,0.333 -0.478,40.986 -20.335,80.708 -3.968,7.936 -3.541,17.357 1.118,24.909 4.668,7.552 12.902,12.143 21.777,12.143 h 65.809 c -1.109,4.087 -1.809,8.354 -1.809,12.8 0,28.237 22.963,51.2 51.2,51.2 28.237,0 51.2,-22.963 51.2,-51.2 0,-4.446 -0.7,-8.713 -1.809,-12.8 H 371.2 c 8.875,0 17.109,-4.591 21.777,-12.143 4.668,-7.544 5.086,-16.973 1.118,-24.909 z M 256,405.606 c -14.114,0 -25.6,-11.486 -25.6,-25.6 0,-4.762 1.664,-8.977 3.934,-12.8 h 43.332 c 2.27,3.823 3.934,8.038 3.934,12.8 0,14.115 -11.486,25.6 -25.6,25.6 z m -115.2,-64 c 23.04,-46.08 23.04,-92.16 23.04,-92.16 v -46.08 c 0,-50.901 41.259,-92.16 92.16,-92.16 50.901,0 92.16,41.259 92.16,92.16 v 46.08 c 0,0 0,46.08 23.04,92.16 -23.04,0 -207.36,0 -230.4,0 z"
   id="path31861"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31871"
               style="fill:#1a1a1a">
	<g
   id="g31869"
   style="fill:#1a1a1a">
		<path
   d="m 428.809,108.16 -21.598,13.679 c 17.63,27.733 27.989,60.527 27.989,95.761 0,35.234 -10.359,68.028 -27.998,95.761 L 428.8,327.04 c 20.156,-31.701 32,-69.18 32,-109.44 0,-40.26 -11.844,-77.739 -31.991,-109.44 z"
   id="path31867"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31877"
               style="fill:#1a1a1a">
	<g
   id="g31875"
   style="fill:#1a1a1a">
		<path
   d="M 104.798,121.839 83.191,108.16 C 63.044,139.861 51.2,177.34 51.2,217.6 c 0,40.26 11.844,77.739 31.991,109.44 l 21.598,-13.679 C 87.159,285.628 76.8,252.834 76.8,217.6 c 0,-35.234 10.359,-68.028 27.998,-95.761 z"
   id="path31873"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31883"
               style="fill:#1a1a1a">
	<g
   id="g31881"
   style="fill:#1a1a1a">
		<path
   d="m 472.013,80.794 -21.606,13.679 c 22.664,35.669 35.993,77.832 35.993,123.127 0,45.295 -13.329,87.458 -35.994,123.127 l 21.606,13.679 C 497.195,314.778 512,267.93 512,217.6 512,167.27 497.195,120.422 472.013,80.794 Z"
   id="path31879"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31889"
               style="fill:#1a1a1a">
	<g
   id="g31887"
   style="fill:#1a1a1a">
		<path
   d="M 61.594,94.473 39.987,80.794 C 14.805,120.422 0,167.27 0,217.6 0,267.93 14.805,314.778 39.987,354.406 L 61.593,340.727 C 38.929,305.058 25.6,262.895 25.6,217.6 25.6,172.305 38.929,130.142 61.594,94.473 Z"
   id="path31885"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31891"
               style="fill:#1a1a1a">
</g><g
               id="g31893"
               style="fill:#1a1a1a">
</g><g
               id="g31895"
               style="fill:#1a1a1a">
</g><g
               id="g31897"
               style="fill:#1a1a1a">
</g><g
               id="g31899"
               style="fill:#1a1a1a">
</g><g
               id="g31901"
               style="fill:#1a1a1a">
</g><g
               id="g31903"
               style="fill:#1a1a1a">
</g><g
               id="g31905"
               style="fill:#1a1a1a">
</g><g
               id="g31907"
               style="fill:#1a1a1a">
</g><g
               id="g31909"
               style="fill:#1a1a1a">
</g><g
               id="g31911"
               style="fill:#1a1a1a">
</g><g
               id="g31913"
               style="fill:#1a1a1a">
</g><g
               id="g31915"
               style="fill:#1a1a1a">
</g><g
               id="g31917"
               style="fill:#1a1a1a">
</g><g
               id="g31919"
               style="fill:#1a1a1a">
</g></g></g></g></g><polygon
       style="display:none;fill:#00ff00;fill-opacity:1"
       points="26.305,3.123 32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 "
       id="checkConnect"
       transform="matrix(0.23686156,0,0,0.27836132,150.56012,179.47822)" /></g><style
     type="text/css"
     id="style11901">
	.st0{fill:#333435;}
	.st1{font-family:'MyriadPro-Regular';}
	.st2{font-size:15.1008px;}
	.st3{font-size:18px;}
	.st4{font-size:21px;}
	.st5{font-size:13.4951px;}
	.st6{font-size:12.1191px;}
	.st7{font-size:16.5828px;}
	.st8{font-size:9.9589px;}
	.st9{font-family:'Calculator';}
	.st10{font-size:45.1149px;}
	.st11{letter-spacing:4;}
	.st12{font-size:23px;}
	.st13{fill:#BABCBF;}
	.st14{fill:#A2A4A8;}
	.st15{fill:#202024;}
	.st16{fill:#D6D7D9;}
	.st17{fill:#BCBEBF;}
	.st18{fill:#4E4E53;}
	.st19{fill:#A84B49;}
	.st20{fill:#C1C1BD;}
	.st21{fill:#101012;}
	.st22{fill:#F7F7F7;}
	.st23{fill:#8B8D8F;}
	.st24{fill:#C7DA9D;}
	.st25{fill:#6D454A;}
	.st26{fill:#A35559;}
	.st27{fill:#282834;}
</style></svg>

        `,
    };
  },
  methods: {
    async showSvg(msg) {
      this.open = true;
      const { check, bateria, detalle, consumo, campana } =
        toSvg.toParseSvg(msg);
      consumo.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      detalle.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      bateria.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      campana.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      check.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      const levels = ["bat_L1", "bat_L2", "bat_L3", "bat_L4", "bat_L5"];

      for (let i = 0; i < levels.length; i++) {
        if (
          msg.porcentajeBateria > i * 20 &&
          msg.porcentajeBateria <= (i + 1) * 20
        ) {
          for (let j = i; j < levels.length; j++) {
            document.getElementById(levels[j]).style.display = "none";
          }
          break;
        }
      }
    },
  },
};
</script>

<style></style>
