<template>
  <GmapMap
    :center="center"
    :zoom="zoomMap"
    :options="{
      zoomControl: false,
    }"
    style="width: 100%; height: 80%"
  >
    <GmapMarker
      v-for="(m, index) in devices"
      :key="index"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      :icon="{ url: m.detalle.icon }"
      @click="clickMarker(m)"
    />
    <v-bottom-sheet v-model="sheet" width="150vh">
      <v-sheet class="rounded-lg rounded-b-0">
        <div class="ma-3">
          <WaterInfo v-if="condicional == 1" ref="mostarInfo" />
          <EnergiaInfo v-if="condicional == 2" ref="mostarInfo" />
          <GasInfo v-if="condicional == 3" ref="mostarInfo" />
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </GmapMap>
</template>
<script>
import GasInfo from "@/views/Pages/Reports/Components/Gas/GasInfo.vue";
import WaterInfo from "@/views/Pages/Reports/Components/water/WaterInfo.vue";
import BackendApi from "@/services/backend.service";
import EnergiaInfo from "@/views/Pages/Reports/Components/Energia/EnergiaInfo.vue";
import moment from "moment";
export default {
  components: {
    GasInfo,
    EnergiaInfo,
    WaterInfo,
  },
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      center: {
        lat: -12.117917755255931,
        lng: -77.04491191797541,
      },
      zoomMap: 13,
      sheet: false,
      detalleMarker: null,
      device: [],
      condicional: 0,
    };
  },
  computed: {
    isTelecontrol() {
      if (!this.detalleMarker) return false;
      return (
        this.detalleMarker.device_product.telecontrol === true &&
        this.detalleMarker.device_product.telemetry === false
      );
    },
    isTelemetry() {
      if (!this.detalleMarker) return false;

      return (
        this.detalleMarker.device_product.telecontrol === false &&
        this.detalleMarker.device_product.telemetry === true
      );
    },
    isTelecontrolTelemetria() {
      if (!this.detalleMarker) return false;

      return (
        this.detalleMarker.device_product.telecontrol === true &&
        this.detalleMarker.device_product.telemetry === true
      );
    },
    hasDeviceData() {
      if (!this.detalleMarker) return false;
      if (!this.detalleMarker.device_data.length > 0) return false;
      // return (
      //   this.detalleMarker.device_product.telecontrol === false &&
      //   this.detalleMarker.device_product.telemetry === true
      // );
      return true;
    },
  },
  methods: {
    clickMarker(m) {
      this.detalleMarker = m.detalle;
      this.device = this.detalleMarker;
      this.condicional = this.detalleMarker.device_product.deviceClassId;
      this.zoomMap = 16;
      this.sheet = true;
      this.center = m.position;
      setTimeout(() => {
        this.$refs.mostarInfo.mostrarDatosMap(this.detalleMarker);
        this.getDataDevice(this.detalleMarker.id);
        this.$refs.mostarInfo.getDate(this.detalleMarker.id);
      }, 4);
    },

    getDataDevice(a) {
      BackendApi.get("/deviceData/lastDeviceData/" + a)
        .then((response) => {
          if (response.data.success) {
            const now24 = moment(new Date())
              .subtract(1, "d")
              .format("DD-MM-YYYY hh:mm:ss");
            this.msg = response.data.data;
            this.msg.checkConnect2 =
              moment(response.data.lastDeviceData.createdAt).format(
                "DD-MM-YYYY hh:mm:ss"
              ) > now24
                ? true
                : false;
            this.msg.device = this.device.device_external_id;
            this.msg.alias = this.device.alias;
            this.msg.caConsumo =
              (response.data.data.value_in_momment + this.device.calibrar) *
              1000;
            this.msg.checkConnect = response.data.checkConnect;
            this.msg.sFraude = response.data.data.fraude;
            // this.msg.s_energia = response.data.data.bateria;
            this.msg.bateria = response.data.data.bateria;
            this.msg.s_energia = false;
            this.msg.ubicacion =
              Number(this.device.latitud).toFixed(5) +
              "," +
              Number(this.device.longitud).toFixed(5);
            this.msg.UMedicion = moment(response.data.data.createdAt).format(
              "YYYY-MM-DD hh:mm:ss"
            );

            this.status_ctlr = response.data.data.status_ctlr;

            this.showDataDevice = true;
            setTimeout(() => {
              this.$refs.mostarInfo.search(this.msg, this.status_ctlr);
            }, 4);
          }
        })
        .catch(() => {
          this.showDataDevice = false;
        });
    },
  },
};
</script>

<style scoped>
.table-sheet {
  padding-left: 50px;
}

.row-sheet {
  text-align: left;
}

.row-sheet-padding-left {
  padding-left: 10px;
}
</style>
