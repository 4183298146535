<template>
  <div>
    <v-row>
      <v-col id="infoDevice1" v-if="showDataDevice">
        <table>
          <tr>
            <td style="width: 100px">ID usuario:</td>
            <td>
              <strong>{{ device.user.id_usuario }}</strong>
            </td>
          </tr>
          <tr>
            <td>DNI:</td>
            <td>
              <strong>{{ device.user.dni }}</strong>
            </td>
          </tr>
          <tr>
            <td>Nombre:</td>
            <td>
              <strong>{{ device.user.name }} </strong>
            </td>
          </tr>
          <tr>
            <td>Direccion:</td>
            <td>
              <strong>{{ device.user.adress }} </strong>
            </td>
          </tr>
          <tr>
            <td>Suministro:</td>
            <td>
              <strong>{{ device.suministro }} </strong>
            </td>
          </tr>
          <tr>
            <td>Comentarios:</td>
            <td>
              <strong>{{ device.commit }} </strong>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col id="infoDevice2" v-if="showDataDevice">
        <table>
          <tr>
            <td style="width: 100px">IMP/M3</td>
            <td>
              <strong>{{ device.coefficient }}</strong>
            </td>
          </tr>
          <tr>
            <td>TIPO:</td>
            <td v-if="isTelecontrol">
              <strong>Telecontrol</strong>
            </td>
            <td v-if="isTelemetry">
              <strong>telemetría</strong>
            </td>
            <td v-if="isTelecontrolTelemetria">
              <strong>Telecontrol y telemetría</strong>
            </td>
          </tr>
          <tr v-if="hasTelecontrol">
            <td>Actuador:</td>
            <td v-if="status_ctlr"><strong>Energizado</strong></td>
            <td v-else><strong>Corte</strong></td>
            <td>
              <div
                :class="[status_ctlr ? 'actuadorActive' : 'actuadorNoActive']"
              ></div>
            </td>
          </tr>
          <tr>
            <td>Mensual Acumulado:</td>
            <td>
              <strong v-if="isNaN(acumuladoMensual)"
                >0
                {{
                  device.device_product.device_class.measurement_unit
                }}</strong
              >
              <strong v-else
                >{{ acumuladoMensual }}
                {{
                  device.device_product.device_class.measurement_unit
                }}</strong
              >
            </td>
          </tr>
        </table></v-col
      >
      <v-col v-if="showDataDevice">
        <div>
          <EnergiaSvgMedicion
            v-if="isTelemetry && showDataDevice"
            class="svg-size"
            ref="svgAguaMedicion"
          />
          <EnergiaSvgTelecontrol
            v-if="isTelecontrol && showDataDevice"
            class="svg-size"
            ref="svgAguaTelecontrol"
          />
          <EnergiaSvgMedicionTelecontrol
            v-if="isTelecontrolTelemetria && showDataDevice"
            class="svg-size"
            ref="svgAguaMedicionTelecontrol"
          />
        </div>
      </v-col>
    </v-row>
    <v-card v-if="showDataDevice && showDataDeviceMap">
      <v-card-title>
        <v-card-title>
          <h5>Tendencia del consumo</h5>
        </v-card-title>
      </v-card-title>
      <v-card-text>
        <line-chart
          ref="mostarInfoLinechart"
          :items="items"
          :idDeviceString="idDeviceString"
          :service="service"
          :deviceDataId="idDeviceSelected"
          @tendencia="saveTendencia"
        ></line-chart>
      </v-card-text>
      <v-card-actions>
        <h4>Consumo: {{ Number(tendencia).toFixed(3) }} {{ unidad }}-h</h4>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EnergiaSvgMedicion from "@/components/svg/energia/EnergiaSvgMedicion.vue";
import EnergiaSvgTelecontrol from "@/components/svg/energia/EnergiaSvgTelecontrol.vue";
import EnergiaSvgMedicionTelecontrol from "@/components/svg/energia/EnergiaSvgMedicionTelecontrol.vue";
import LineChart from "@/views/Pages/Reports/Components/water/LineChart.vue";
import BackendApi from "@/services/backend.service";
import moment from "moment";
export default {
  components: {
    EnergiaSvgMedicion,
    EnergiaSvgTelecontrol,
    EnergiaSvgMedicionTelecontrol,
    LineChart,
  },
  props: {
    unidad: {
      type: String,
    },
    service: {
      type: String,
    },
  },
  data() {
    return {
      idDeviceString: '',
      showDataDeviceMap: false,
      idDeviceSelected: null,
      tendencia: 0,
      device: [],
      showDataDevice: false,
      acumuladoMensual: 0,
      status_ctlr: false,
    };
  },
  computed: {
    items() {
      return [
        {
          id: 1,
          value: "Consumo " + this.unidad + "-h",
        },
        {
          id: 2,
          value: "Perfil " + this.unidad + "-h",
        },
      ];
    },
    isTelecontrol() {
      if (!this.device) return false;
      return (
        this.device.device_product.telecontrol === true &&
        this.device.device_product.telemetry === false
      );
    },
    isTelemetry() {
      if (!this.device) return false;
      return (
        this.device.device_product.telecontrol === false &&
        this.device.device_product.telemetry === true
      );
    },
    isTelecontrolTelemetria() {
      if (!this.device) return false;
      return (
        this.device.device_product.telecontrol === true &&
        this.device.device_product.telemetry === true
      );
    },
    hasTelecontrol() {
      if (!this.device) return false;
      return this.device.device_product.telecontrol === true;
    },
  },
  methods: {
    saveTendencia(algo) {
      this.tendencia = algo;
    },
    cerrarDatos() {
      this.showDataDevice = false;
    },
    search(msg, status_ctlr) {
      this.status_ctlr = status_ctlr;
      this.idDeviceString = msg.device;
      console.log(msg);
      if (this.isTelecontrol) this.$refs.svgAguaTelecontrol.showSvg(msg);
      if (this.isTelemetry) this.$refs.svgAguaMedicion.showSvg(msg);
      if (this.isTelecontrolTelemetria)
        this.$refs.svgAguaMedicionTelecontrol.showSvg(msg);
    },
    mostrarDatos(device, idDeviceSelected) {
      this.device = device;
      this.idDeviceSelected = idDeviceSelected;
      this.showDataDevice = true;
      this.showDataDeviceMap = true;
    },
    mostrarDatosMap(device, idDeviceSelected) {
      this.device = device;
      this.idDeviceSelected = idDeviceSelected;
      this.showDataDevice = true;
    },
    async getDate(idDeviceSelected) {
      const payload = {
        startDate:
          moment(moment(new Date()).format("MM/01/YYYY 00:00:00")).valueOf() /
          1000,
        endDate:
          moment(
            moment(new Date()).add(1, "months").format("MM/01/YYYY 00:00:00")
          ).valueOf() / 1000,
      };
      BackendApi.post(
        "/deviceData/deviceData/" + idDeviceSelected,
        payload
      ).then((response) => {
        if (response.data.success) {
          this.acumulado = [];
          response.data.data.forEach((element) => {
            this.acumulado.push(element.value);
          });
          this.acumuladoMensual = (
            this.acumulado[this.acumulado.length - 1] - this.acumulado[0]
          ).toFixed(3);
          this.$refs.mostarInfoLinechart.mostrarDatos(
            this.device,
            this.status_ctlr,
            this.acumuladoMensual,
            this.unidad + '-h'
          );
        }
      });
    },
  },
};
</script>
